import React from 'react';
import { useLocation } from '@reach/router';
import { FacebookShareButton, LinkedinShareButton } from 'react-share';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { container, icon } from './social-share.module.scss';
import IconFb from '../../assets/images/svg/icon-fb.svg';
import IconLi from '../../assets/images/svg/icon-li.svg';

interface ISocialShareProps {
    className?: string;
}

const SocialShare: React.FC<ISocialShareProps> = ({ className = '' }) => {
    const { t } = useI18next();
    const { href } = useLocation();
    return (
        <div className={`${container} ${className}`}>
            <FacebookShareButton url={href} className={icon} aria-label={t('share.facebook')}>
                <IconFb />
            </FacebookShareButton>
            <LinkedinShareButton url={href} className={icon} aria-label={t('share.linkedin')}>
                <IconLi />
            </LinkedinShareButton>
        </div>
    );
};

export default SocialShare;
