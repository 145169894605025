import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import Link from '../../../plugins/gatsby-plugin-ap-i18next/src/link';

import {
    container,
    link,
    prev,
    next,
    linkText,
    arrowContainer,
    button,
} from './post-navigation.module.scss';
import ArrowLeft from '../../assets/images/svg/arrow-left.svg';
import ArrowRight from '../../assets/images/svg/arrow-right.svg';

import Button from '../atoms/button';

interface IPostNavigationProps {
    className?: string;
    previousSlug: string | undefined;
    nextSlug: string | undefined;
}

const PostNavigation: React.FC<IPostNavigationProps> = ({ className, previousSlug, nextSlug }) => {
    const { t } = useI18next();
    return (
        <div className={`${container} ${className}`}>
            {previousSlug && (
                <Link to={previousSlug} className={` ${link} ${prev} `}>
                    <div className={arrowContainer}>
                        <ArrowLeft />
                    </div>
                    <span className={linkText}> {t('post.news.prev')} </span>
                </Link>
            )}
            <Button as="link" to="news" className={button}>
                {t('post.news.button')}
            </Button>
            {nextSlug && (
                <Link to={nextSlug} className={` ${link} ${next} `}>
                    <span className={linkText}>{t('post.news.next')}</span>
                    <div className={arrowContainer}>
                        <ArrowRight />
                    </div>
                </Link>
            )}
        </div>
    );
};

export default PostNavigation;
