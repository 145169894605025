import React from 'react';
import { graphql } from 'gatsby';

import { layout, content, share, navigation, related } from './post.module.scss';
import { gridSmall } from '../styles/grid.module.scss';
import pageContexts from '../config/page-contexts';
import { relations } from '../config/relations';
import { translationKeys } from '../config/translation-keys';
import { IPost, IPostCard } from '../models/post.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import useMedia from '../hooks/use-media';
import { useTranslation } from '../hooks/use-translation';
import { getBreadcrumbItem } from '../utils/get-breadcrumbs';
import { getNodes } from '../utils/get-nodes';

import MainLayout from '../layouts/main-layout';
import PostContent from '../components/organisms/post-content';
import SocialShare from '../components/organisms/social-share';
import PostNavigation from '../components/organisms/post-navigation';
import RelatedPosts from '../components/organisms/related-posts';

interface IPostProps {
    readonly data: {
        post: IPost;
        nextPost?: Pick<IPost, 'slug'>;
        previousPost?: Pick<IPost, 'slug'>;
        allRelatedPost: IQueryAllResult<IPostCard>;
    };
}

const Post: React.FC<IPostProps> = ({ data }) => {
    const { post, nextPost, previousPost, allRelatedPost } = data;
    const { media } = post;

    const { title, lead } = useTranslation(post, translationKeys.post);
    const ogImage = useMedia({ media, relation: relations.ogImage });
    const breadcrumbs = [getBreadcrumbItem(pageContexts.blog), getBreadcrumbItem(post, 'title')];

    const relatedPosts = getNodes(allRelatedPost);

    return (
        <MainLayout
            isVisibleHeaderBg={true}
            className={`${layout} ${gridSmall}`}
            SEOProps={{ title, description: lead, image: ogImage.url }}
            breadcrumbs={breadcrumbs}
        >
            <PostContent className={content} post={post} />
            <SocialShare className={share} />
            <PostNavigation
                className={navigation}
                previousSlug={previousPost?.slug}
                nextSlug={nextPost?.slug}
            />
            {relatedPosts.length > 0 && <RelatedPosts className={related} posts={relatedPosts} />}
        </MainLayout>
    );
};

export const query = graphql`
    query (
        $language: String!
        $newsId: Int!
        $previousNewsId: Int
        $nextNewsId: Int
        $hasPrevious: Boolean!
        $hasNext: Boolean!
        $relatedNewsIds: [Int]!
    ) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        post(newsId: { eq: $newsId }) {
            ...postFields
        }

        previousPost: post(newsId: { eq: $previousNewsId }) @include(if: $hasPrevious) {
            slug
        }

        nextPost: post(newsId: { eq: $nextNewsId }) @include(if: $hasNext) {
            slug
        }

        allRelatedPost: allPost(
            sort: { fields: publishedAt, order: DESC }
            filter: { newsId: { in: $relatedNewsIds } }
            limit: 3
        ) {
            edges {
                node {
                    ...postCardFields
                }
            }
        }
    }
`;

export default Post;
