import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    container,
    header,
    titleText,
    leadText,
    imageBox,
    body,
    source,
    sourceText,
} from './post-content.module.scss';
import { relations } from '../../config/relations';
import { translationKeys } from '../../config/translation-keys';
import { IPost } from '../../models/post.model';
import useMedia from '../../hooks/use-media';
import { useTranslation } from '../../hooks/use-translation';

import PostMeta from '../atoms/post-meta';
import Markdown from '../hoc/markdown';

interface IPostContentProps {
    className?: string;
    post: IPost;
}

const PostContent: React.FC<IPostContentProps> = ({ className = '', post }) => {
    const { t } = useI18next();
    const { media, sourceLink } = post;

    const { title, lead, content } = useTranslation(post, translationKeys.post);
    const { image, alt } = useMedia({ media, relation: relations.mainImage });

    return (
        <article className={`${container} ${className}`}>
            <div className={header}>
                <h1 className={titleText}>{title}</h1>
                <PostMeta post={post} />
            </div>
            {image && <GatsbyImage alt={alt || ''} image={image} className={imageBox} />}
            {lead && <h2 className={leadText}>{lead}</h2>}
            {content && <Markdown className={body}>{content}</Markdown>}
            {sourceLink && (
                <p className={source}>
                    {t('news.source.label')}:{' '}
                    <a
                        className={sourceText}
                        href={sourceLink}
                        target="_blank"
                        rel="noreferrer noopener"
                    >
                        {sourceLink}
                    </a>
                </p>
            )}
        </article>
    );
};

export default PostContent;
