import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { container, title, list, card } from './related-posts.module.scss';
import { gridSmall } from '../../styles/grid.module.scss';
import { IPostCard } from '../../models/post.model';

import PostCard from '../molecules/post-card';

interface IRelatedPostsProps {
    className?: string;
    posts: IPostCard[];
}

const RelatedPosts: React.FC<IRelatedPostsProps> = ({ className = '', posts }) => {
    const { t } = useI18next();
    return (
        <div className={`${gridSmall} ${container} ${className}`}>
            <h2 className={title}>{t('news.related.title')}</h2>
            <ul className={list}>
                {posts.map((post) => {
                    return (
                        <li key={`post-card-${post.newsId}`}>
                            <PostCard className={card} postCard={post} />
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default RelatedPosts;
